<template>
  <div class="storeroom">
    <van-nav-bar
      title="小金库"
      left-arrow
      fixed
      safe-area-inset-top
      placeholder
      @click-left="leftClick">
    </van-nav-bar>
<!--    <div class="head">
      <div class="total">
        <div>
          <div>黄金总重(克)</div>
          <div class="weight">{{ userInfo.goldIncomingFreeWeight }}</div>
        </div>
        <div>
          <div>白银总重(克)</div>
          <div class="weight">{{ userInfo.baiyinIncomingFreeWeight }}</div>
        </div>
        <div>
          <div>铂金总重(克)</div>
          <div class="weight">{{ userInfo.ptIncomingFreeWeight }}</div>
        </div>
      </div>
      <div class="total">
        <div>
          <div>钯金总重(克)</div>
          <div class="weight">{{ userInfo.pdIncomingFreeWeight }}</div>
        </div>
        <div>
          <div>钻石总重(克)</div>
          <div class="weight">{{ userInfo.zsIncomingFreeWeight }}</div>
        </div>
        <div style="visibility: hidden">
          <div>钻石总重(克)</div>
          <div class="weight"></div>
        </div>
      </div>
    </div>-->
    <div class="head1">
      <div class="flex top">
        <div class="category"></div>
        <div class="title">折足总重</div>
        <div class="title">剩余折足总重</div>
      </div>
      <div class="flex top">
        <div class="category">黄金</div>
        <div class="number">{{ userInfo.goldIncomingTotalWeight }}</div>
        <div class="number">{{ userInfo.goldIncomingFreeWeight }}</div>
      </div>
      <div class="flex top">
        <div class="category">白银</div>
        <div class="number">{{ userInfo.baiyinIncomingTotalWeight }}</div>
        <div class="number">{{ userInfo.baiyinIncomingFreeWeight }}</div>
      </div>
      <div class="flex top">
        <div class="category">铂金</div>
        <div class="number">{{ userInfo.ptIncomingTotalWeight }}</div>
        <div class="number">{{ userInfo.ptIncomingFreeWeight }}</div>
      </div>
      <div class="flex top">
        <div class="category">钯金</div>
        <div class="number">{{ userInfo.pdIncomingTotalWeight }}</div>
        <div class="number">{{ userInfo.pdIncomingFreeWeight }}</div>
      </div>
      <div class="flex top">
        <div class="category">钻石</div>
        <div class="number">{{ userInfo.zsIncomingTotalWeight }}</div>
        <div class="number">{{ userInfo.zsIncomingFreeWeight }}</div>
      </div>
    </div>
    <div class="body">
      <div class="title">明细</div>
      <template v-if="ishow">
        <van-empty
          class="custom-image"
          :image="require('@/assets/images/pic_no information@2x.png')"
          description="暂无数据!"
        />
      </template>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        v-if="!ishow"
      >
        <div
          v-for="(itemslist, index) in storeroomList"
          :key="index"
          class="cards"
        >
          <div class="flex_between">
            <div class="type">
              {{ incomingImportReferenceType[itemslist.type] }}
            </div>
            <div class="time">{{itemslist.created | formatDate_ }}</div>
          </div>
          <div class="total">
            <div class="detail">
              黄金<span :style="{color: itemslist.goldWeight >= 0 ? '#5473ff' : '#ff2d2e', marginLeft: '10px'}">{{itemslist.goldWeight ? itemslist.goldWeight : "--"}}</span>
            </div>
            <div class="detail">
              白银<span :style="{color: itemslist.baiyinWeight >= 0 ? '#5473ff' : '#ff2d2e', marginLeft: '10px'}">{{itemslist.baiyinWeight? itemslist.baiyinWeight : "--"}}</span>
            </div>
            <div class="detail">
              铂金<span :style="{color: itemslist.ptWeight >= 0 ? '#5473ff' : '#ff2d2e', marginLeft: '10px'}">{{itemslist.ptWeight? itemslist.ptWeight : "--"}}</span>
            </div>
          </div>
          <div class="total">
            <div class="detail">
              钯金<span :style="{color: itemslist.pdWeight >= 0 ? '#5473ff' : '#ff2d2e', marginLeft: '10px'}">{{itemslist.pdWeight? itemslist.pdWeight : "--"}}</span>
            </div>
            <div class="detail">
              钻石<span :style="{color: itemslist.zsWeight >= 0 ? '#5473ff' : '#ff2d2e', marginLeft: '10px'}">{{itemslist.zsWeight? itemslist.zsWeight : "--"}}</span>
            </div>
            <div class="detail">
            </div>
          </div>
        </div>
      </van-list>
      <footer class="foot">
        <div class="leftFoot" @click="toMortgage">去存料</div>
        <div class="middleFoot" @click="toShopSell">去现结</div>
        <div class="rightFoot" @click="toSell">去交料</div>
      </footer>
    </div>
    <van-dialog v-model="statementShow" :show-confirm-button="false" get-container="#app" >
      <div class="dialog_title">现价结算</div>
      <div class="centerbox_dialog">
        <div class="flex_between padding6">
          <div style="width: 48%" class="flex_between col999">
            <div>快递费</div>  <div>{{userInfo.expressFee}}</div>
          </div>
          <div style="width: 48%" class="flex_between col999">
            <div>保险费</div>  <div>{{userInfo.insuranceFee}}</div>
          </div>
        </div>
        <div class="flex_between padding6">
          <div style="width: 48%" class="flex_between col999">
            <div>首饰折价</div>  <div>{{userInfo.purificationFee}}</div>
          </div>
          <div style="width: 48%" class="flex_between break">
            <div>交料总克重</div>  <div>{{totalWeight}}</div>
          </div>
        </div>
        <div class="flex_between padding6">
          <div style="width: 48%" class="flex_between break">
            <div>剩余黄金</div>  <div>{{userInfo.goldIncomingFreeWeight}}</div>
          </div>
          <div style="width: 48%" class="flex_between break">
            <div>剩余白银</div>  <div>{{userInfo.baiyinIncomingFreeWeight}}</div>
          </div>
        </div>
        <div class="flex_between padding6">
          <div style="width: 48%" class="flex_between break">
            <div>剩余铂金</div>  <div>{{userInfo.ptIncomingFreeWeight}}</div>
          </div>
          <div style="width: 48%" class="flex_between break">
            <div>剩余钯金</div>  <div>{{userInfo.pdIncomingFreeWeight}}</div>
          </div>
        </div>
      </div>
      <div class="dialog_title" style="text-align: left; margin-bottom: 0" v-if="totalWeight">明细</div>
      <div class="referlist flex_between padding6" v-if="totalWeight">
        <div class="referlistDetail">品类</div>
        <div class="referlistDetail">当前价格</div>
        <div class="referlistDetail">结算克重</div>
      </div>
      <div class="referlist flex_between padding6" v-for="(item,index) in orderList" :key="index">
        <div class="referlistDetail">{{item.name}}</div>
        <div class="referlistDetail">{{item.name === "白银" ? pricebaiy : item.name === "铂金" ? priceboj : item.name === "钯金" ? pricebaj : pricehj}}</div>
        <div class="referlistDetail">{{item.weight}}</div>
      </div>
      <div class="tips">
        注：需铂钯银结算请联系企业微信客服
      </div>
      <div class="flex_between popbtn padding6">
        <div class="gocer closebtn" @click="close" >取消</div>
        <div class="gocer confirmbtn" @click="commit">确定</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import { formatDate_ } from "@/utils/filters";
  import { storeroom, getuser, getGoods, statementCommon } from "@/utils/api";
  import Decimal from "decimal.js";

  export default {
    data() {
      return {
        incomingImportReferenceType: {
          INCOMING: "来料入库",
          SELL: "卖料结算",
          CHANGE_MATERIAL: "换料结算",
          PRE_MORTGAGE: "存料结算",
          MORTGAGE: "存料结算",
          BUY: "买料结算",
          PRE_MATERIAL: "提料结算",
          BUY_COMMIT: "买料结算交料",
          PRE_MATERIAL_COMMIT: "提料结算交料",
          MATERIAL: "提料结算",
        },
        loading: false,
        finished: false,
        page: 0,
        size: 10,
        ishow: true, // 是否有数据
        storeroomList: [], // 客户货物出入明细数据
        userInfo: JSON.parse( localStorage.getItem('userinfo')),
        client:{},
        // 所有商品数据
        allpro:[],
        // 首次加载的数据
        initDatalist: [],
        // 是否开盘
        trade_: false,
        // 提交时间
        priceId: '',
        pricehj:0,
        priceboj:0,
        pricebaj:0,
        pricebaiy:0,
        initPriceName:{
          huangjin:'huangjin9999',
          baiyin:'baiyin9999',
          bajin:"bajin9996",
          bojin:"bojin9996"
        },
        statementShow: false,
        totalWeight: 0, // 现价结算总克重
        orderList: [],
        isClick: false,
        goods: {} // 商品信息
      };
    },
    filters: {
      formatDate_
    },
    mounted() {
      this.onLoad()
    },
    watch: {
      allpro:function(){
        this.updatearr(this.initPriceName)
      }
    },
    methods: {
      async onLoad(){
        const res = await storeroom({page:this.page,size:this.size})
        this.page = res.number + 1
        this.storeroomList.push(...res.content)
        this.loading = false
        if (this.storeroomList.length != 0) {
          this.ishow = false;
        } else {
          this.ishow = true;
        }
        if (res.last == true) {
          this.finished = true
        }
      },
      // 顶部左侧图标点击事件
      leftClick(){
        this.$router.push({
          name:'person'
        })
      },
      toSell() {
        this.$router.push({ name: "sellSettlement",query:{activeName:'SUSPENSE,UNDELIVERED'}});
      },
      toMortgage() {
        this.$router.push({
          name: 'mortgagegold',
        })
      },
      async toShopSell() {
        this.connect()
        this.userInfo = await getuser()
        getGoods("COMMON_STATEMENT", { statementFlag: true }).then(res => {
          this.goods = res
        });
        this.statementShow = true
        // this.totalWeight = new Decimal(this.userInfo.goldIncomingFreeWeight).add(new Decimal(this.userInfo.baiyinIncomingFreeWeight)).add(new Decimal(this.userInfo.ptIncomingFreeWeight)).add(new Decimal(this.userInfo.pdIncomingFreeWeight)).toNumber()
        this.totalWeight = new Decimal(this.userInfo.goldIncomingFreeWeight).toNumber()
        this.orderList = []
        if (this.userInfo.goldIncomingFreeWeight) {
          this.orderList.push({name: "黄金", weight: this.userInfo.goldIncomingFreeWeight})
        }
/*        if (this.userInfo.baiyinIncomingFreeWeight) {
          this.orderList.push({name: "白银", weight: this.userInfo.baiyinIncomingFreeWeight})
        }
        if (this.userInfo.ptIncomingFreeWeight) {
          this.orderList.push({name: "铂金", weight: this.userInfo.ptIncomingFreeWeight})
        }
        if (this.userInfo.pdIncomingFreeWeight) {
          this.orderList.push({name: "钯金", weight: this.userInfo.pdIncomingFreeWeight})
        }*/
      },
      unzip(b64Data) {
        let strData = atob(b64Data);
        const charData = strData.split("").map(function(x) {
          return x.charCodeAt(0);
        });
        const binData = new Uint8Array(charData);
        const data = pako.inflate(binData);
        strData = String.fromCharCode.apply(null, new Uint16Array(data));
        return decodeURIComponent(escape(strData));
      },
      onConnected() {
        const topic = "/price/all";
        this.client.subscribe(topic, this.responseCallback, this.onFailed);
      },
      connect() {
        this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
        // ---初始化mqtt客户端，并连接mqtt服务
        const headers = {
          login: "username",
          passcode: "password"
        };
        this.client.connect(headers, this.onConnected, this.onFailed);
        this.client.debug = null;
      },
      onFailed(frame) {
        if (!this.client.connected) {
          setTimeout(() => {
            this.connect();
          }, 1000);
        }
      },
      deepMerge(...objs) {
        const than = this
        const result = Object.create(null)
        objs.forEach(obj => {
          if (obj) {
            Object.keys(obj).forEach(key => {
              const val = obj[key]
              if (than.isPlainObject(val)) {
                // 递归
                if (than.isPlainObject(result[key])) {
                  result[key] = than.deepMerge(result[key], val)
                } else {
                  result[key] = than.deepMerge(val)
                }
              } else {
                //  数组也要重新赋值  不然依然会引用到其他的
                if (Array.isArray(val)) {
                  result[key] = [...val]
                } else {
                  result[key] = val
                }
              }
            })
          }
        })
        return result
      },
      isPlainObject(val) {
        const toString = Object.prototype.toString
        return toString.call(val) === '[object Object]'
      },
      responseCallback (frame) {
        this.allpro = []
        const resbody = eval('['+this.unzip(frame.body)+']')[0]
        const parse_res = JSON.parse(JSON.stringify(resbody));
        this.trade_ = resbody.trade;
        this.priceId = resbody.id
        // console.log('postTime1',this.postTime);
        if(this.firstloadData){
          this.initDatalist = resbody
          this.firstloadData = false
          for(const i in this.initDatalist){
            if(this.initDatalist[i]['prices'] != undefined){

              this.allpro.push(this.initDatalist[i]['prices'])
            }
          }
        }else{
          this.initDatalist = this.deepMerge(this.initDatalist,parse_res)
          for(const i in this.initDatalist){
            if(this.initDatalist[i]['prices'] != undefined){
              this.allpro.push(this.initDatalist[i]['prices'])
            }
          }
        }
      },
      // 价格变动更新视图
      pricenew(obj){
        this.allpro.find((x)=>{
          if(x[obj.huangjin]){
            this.pricehj = x[obj.huangjin]['huigou']
          }
          if(x[obj.baiyin]){
            this.pricebaiy = x[obj.baiyin]['huigou']
          }
          if(x[obj.bajin]){
            this.pricebaj = x[obj.bajin]['huigou']
          }
          if(x[obj.bojin]){
            this.priceboj = x[obj.bojin]['huigou']
          }
        })
      },
      // 更新数组
      updatearr(obj){
        this.pricenew(obj)
      },
      destroyed() {
        this.client.disconnect(function() {
          console.log(111)
        })
      },
      // 关闭弹窗
      close() {
        this.statementShow = false
        this.orderList = []
        this.isClick = false
      },
      async commit() {
        if (this.isClick) {
          setTimeout(() => {
            this.isClick = false
          }, 1000)
          return false
        }
        this.isClick = true
        if (!this.totalWeight) {
          this.$toast('可用折足克重不足!')
          this.isClick = false
          return
        }
        if (this.pricehj == 0 || this.pricebaiy == 0 || this.priceboj == 0 || this.pricebaj == 0) {
          this.$toast('当前价格异常，请稍后重试!')
          this.isClick = false
          return
        }
        if (!this.trade_) {
          this.$toast('暂未开盘!')
          this.isClick = false
          return
        }
        if (+this.userInfo.goldIncomingFreeWeight >= 1) {
          this.$toast('超出1g如需现价结算请联系客服或者自行定价结算')
          this.isClick = false
          return
        }
        if (this.orderList.length > 0) {
          const orderIdList = []
          const items = []
          let money = new Decimal('0')
          let storageFee = new Decimal('0')
          this.orderList.forEach((item,index) => {
            const resFilter = this.goods.filter(function(item1, index) {
              return item1.name.includes(item.name);
            });
            let recentGoods = {}
            if (resFilter.length) {
              recentGoods = resFilter[0];
            } else {
              recentGoods = resFilter[0];
            }
            items.push({
              afterWeight: item.weight,
              discountWeight: item.weight,
              extra: {
                orderWeight: item.weight,
                freeWeight: undefined,
              },
              fromPlatform: "MOBILE_PWA",
              goods: {
                id: recentGoods.id,
                key: recentGoods.key,
                name: recentGoods.name
              },
              money: Number(
                new Decimal(item.weight)
                  .mul(new Decimal(item.name === "白银" ? this.pricebaiy : item.name === "铂金" ? this.priceboj : item.name === "钯金" ? this.pricebaj : this.pricehj))
                  .toFixed(2, Decimal.ROUND_FLOOR)
              ),
              percent: 100,
              price: item.name === "白银" ? this.pricebaiy : item.name === "铂金" ? this.priceboj : item.name === "钯金" ? this.pricebaj : this.pricehj,
              priceId: this.priceId,
              sort: index,
              statementOrderType: "SHOP_STATEMENT_SELL",
              storageFee: 0,
              unfinishStorageFee: 0,
              weight: item.weight
            })
            money = money.plus(new Decimal(items[index].money?items[index].money:0))
            storageFee = storageFee.plus(new Decimal(items[index].unfinishStorageFee?items[index].unfinishStorageFee:0).toFixed(2, Decimal.ROUND_HALF_UP))
          })
          money = money
            .minus(new Decimal(this.userInfo.purificationFee?this.userInfo.purificationFee:0))
            .minus(new Decimal(this.userInfo.expressFee?this.userInfo.expressFee:0))
            .minus(new Decimal(this.userInfo.insuranceFee?this.userInfo.insuranceFee:0))
            .minus(new Decimal(storageFee?storageFee:0))
          const postData = {
            // baiyinReferenceWeight: this.userInfo.baiyinIncomingFreeWeight,
            baiyinReferenceWeight: 0,
            expressMoney: this.userInfo.expressFee,
            goldReferenceWeight: this.userInfo.goldIncomingFreeWeight,
            insFee: this.userInfo.insuranceFee,
            items: items,
            money: (new Decimal(money.toFixed(2, Decimal.ROUND_HALF_UP))).toNumber(),
            orderIdList: orderIdList,
            // pdReferenceWeight: this.userInfo.pdIncomingFreeWeight,
            pdReferenceWeight: 0,
            // ptReferenceWeight: this.userInfo.ptIncomingFreeWeight,
            ptReferenceWeight: 0,
            zsReferenceWeight: 0,
            purificationFee: this.userInfo.purificationFee,
            storageFee: (new Decimal(storageFee.toFixed(2, Decimal.ROUND_FLOOR))).toNumber(),
            totalDiscountWeight: this.totalWeight,
            totalWeight: 0,
            userId: this.userInfo.uid,
          }
          await statementCommon("SELL", postData)
          this.$toast.success({
            message: "提交成功,请核对确认结算单",
            duration: 2000,
            onClose: async () => {
              this.statementShow = false
              this.orderList = []
              this.totalWeight = ""
              this.isClick = false
              this.$router.push({ name: "person" });
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped="true">
.storeroom {
  background-color: #f5f5f5;
  min-height: 100%;
  .head {
    border-radius: 10px;
    background: url('~@/assets/images/storeroomBg_yellow.png') no-repeat;
    margin: 50px 32px 40px;
    padding: 48px 64px;
    .total {
      display: flex;
      text-align: center;
      justify-content: space-between;
      font-size: 24px;
      color: #ffffff;
      .weight {
        margin-top: 10px;
        font-weight: 500;
        font-size: 40px;
      }
    }
    .total:last-child{
      margin-top: 32px;
    }
  }
  .head1 {
    margin: 24px 32px;
    padding: 0 24px;
    background: #ffffff;
    font-size: 24px;
    color: #333333;
    .top {
      padding-top: 24px;
      text-align: center;
      .category {
        width: 33%;
      }
      .title {
        width: 33%;
        color: #919191;
      }
      .number {
        width: 33%;
        margin-left: 30px;
      }
      &:last-child {
        padding-bottom: 24px;
      }
    }
  }
  .body {
    margin: 0 32px 80px;
    padding-bottom: 10px;
    .title {
      color: #242424;
      font-size: 32px;
      font-weight: 500;
    }
    .cards {
      background: #ffffff;
      margin-top: 20px;
      padding: 20px 32px 20px;
      .type {
        color: #1a1a1a;
        font-size: 32px;
        font-weight: 500;
      }
      .time {
        font-size: 24px;
        color: #999999;
      }
      .total {
        display: flex;
        text-align: center;
        justify-content: space-between;
        font-size: 28px;
        color: #1a1a1a;
        margin-top: 24px;
        .detail {
          width: 200px;
        }
        .detail:first-child {
          text-align: left;
        }
        .detail:last-child {
          text-align: right;
        }
      }
    }
  }
}
::v-deep {
  .van-loading__text {
    font-size: 30px;
  }
  .van-list__finished-text {
    font-size: 30px;
  }
  .van-pull-refresh__head {
    font-size: 30px !important;
  }
  .van-empty__image {
    width: 420px;
    height: 260px;
  }
  .van-nav-bar{
    height: 100px;
    .van-nav-bar__content{
      line-height: 100px;
      height: 100px;
      .van-nav-bar__title{
        font-size: 40px;
      }
      .van-icon{
        font-size: 40px;
        color: #1e1e1e;
      }
    }
  }
}
.van-empty {
  margin-top: 150px;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.foot{
  height: 84px;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  .leftFoot,.rightFoot, .middleFoot{
    width: 33%;
    text-align: center;
    background-color: #5473FF;
    line-height: 84px;
    font-size: 34px;
    color: #fff;
  }
  .rightFoot{
    background-color: #FF2D2E;
  }
  .middleFoot {
    background: #FFBB3E;
  }
}
.van-dialog{
  width: 90%;
  padding: 0 40px 30px;
  .title{
    color: #242424;
  }
  .van-field{
    font-size: 28px;
  }
}
.popbtn{
  margin-top: 25px;
  div{
    margin-top: 5px!important;
    border-top: none!important;
    width: 260px;
    text-align: center;
    border-radius: 50px;
  }
}
.dialog_title{
  font-size: 32px;
  text-align: center;
  margin: 15px 0;
  color: #242424;
}
.referlist {
  border-bottom: 1px solid #cccccc;
  height: 50px;
  font-size: 24px;
  .referlistDetail {
    width: 33%;
    text-align: center;
  }
}
.break{
  padding: 2px 0;
  color: #242424;
}
.col999{
  padding: 2px 0;
  color: #999999;
}
.gocer{
  font-size: 32px;
  padding: 14px 0;
  margin-top: 30px;
  color:#fff;
  text-align: center;
}
.padding6 {
  padding: 12px 0;
}
.centerbox_dialog{
  text-align: left;
  // padding: 0 10px;
  font-size: 28px;
}
.closebtn{
  background-color: #D1D1D1;
}
.confirmbtn{
  background-color:#FF2D2E ;
}
.tips {
  margin-top: 14px;
  color: #ff2d2e;
  font-size: 20px;
}
</style>
