var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "storeroom" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "小金库",
          "left-arrow": "",
          fixed: "",
          "safe-area-inset-top": "",
          placeholder: ""
        },
        on: { "click-left": _vm.leftClick }
      }),
      _c("div", { staticClass: "head1" }, [
        _vm._m(0),
        _c("div", { staticClass: "flex top" }, [
          _c("div", { staticClass: "category" }, [_vm._v("黄金")]),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.userInfo.goldIncomingTotalWeight))
          ]),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.userInfo.goldIncomingFreeWeight))
          ])
        ]),
        _c("div", { staticClass: "flex top" }, [
          _c("div", { staticClass: "category" }, [_vm._v("白银")]),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.userInfo.baiyinIncomingTotalWeight))
          ]),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.userInfo.baiyinIncomingFreeWeight))
          ])
        ]),
        _c("div", { staticClass: "flex top" }, [
          _c("div", { staticClass: "category" }, [_vm._v("铂金")]),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.userInfo.ptIncomingTotalWeight))
          ]),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.userInfo.ptIncomingFreeWeight))
          ])
        ]),
        _c("div", { staticClass: "flex top" }, [
          _c("div", { staticClass: "category" }, [_vm._v("钯金")]),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.userInfo.pdIncomingTotalWeight))
          ]),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.userInfo.pdIncomingFreeWeight))
          ])
        ]),
        _c("div", { staticClass: "flex top" }, [
          _c("div", { staticClass: "category" }, [_vm._v("钻石")]),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.userInfo.zsIncomingTotalWeight))
          ]),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.userInfo.zsIncomingFreeWeight))
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("明细")]),
          _vm.ishow
            ? [
                _c("van-empty", {
                  staticClass: "custom-image",
                  attrs: {
                    image: require("@/assets/images/pic_no information@2x.png"),
                    description: "暂无数据!"
                  }
                })
              ]
            : _vm._e(),
          !_vm.ishow
            ? _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.storeroomList, function(itemslist, index) {
                  return _c("div", { key: index, staticClass: "cards" }, [
                    _c("div", { staticClass: "flex_between" }, [
                      _c("div", { staticClass: "type" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.incomingImportReferenceType[itemslist.type]
                            ) +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm._f("formatDate_")(itemslist.created)))
                      ])
                    ]),
                    _c("div", { staticClass: "total" }, [
                      _c("div", { staticClass: "detail" }, [
                        _vm._v(" 黄金"),
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                itemslist.goldWeight >= 0
                                  ? "#5473ff"
                                  : "#ff2d2e",
                              marginLeft: "10px"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                itemslist.goldWeight
                                  ? itemslist.goldWeight
                                  : "--"
                              )
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "detail" }, [
                        _vm._v(" 白银"),
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                itemslist.baiyinWeight >= 0
                                  ? "#5473ff"
                                  : "#ff2d2e",
                              marginLeft: "10px"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                itemslist.baiyinWeight
                                  ? itemslist.baiyinWeight
                                  : "--"
                              )
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "detail" }, [
                        _vm._v(" 铂金"),
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                itemslist.ptWeight >= 0 ? "#5473ff" : "#ff2d2e",
                              marginLeft: "10px"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                itemslist.ptWeight ? itemslist.ptWeight : "--"
                              )
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "total" }, [
                      _c("div", { staticClass: "detail" }, [
                        _vm._v(" 钯金"),
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                itemslist.pdWeight >= 0 ? "#5473ff" : "#ff2d2e",
                              marginLeft: "10px"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                itemslist.pdWeight ? itemslist.pdWeight : "--"
                              )
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "detail" }, [
                        _vm._v(" 钻石"),
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                itemslist.zsWeight >= 0 ? "#5473ff" : "#ff2d2e",
                              marginLeft: "10px"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                itemslist.zsWeight ? itemslist.zsWeight : "--"
                              )
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "detail" })
                    ])
                  ])
                }),
                0
              )
            : _vm._e(),
          _c("footer", { staticClass: "foot" }, [
            _c(
              "div",
              { staticClass: "leftFoot", on: { click: _vm.toMortgage } },
              [_vm._v("去存料")]
            ),
            _c(
              "div",
              { staticClass: "middleFoot", on: { click: _vm.toShopSell } },
              [_vm._v("去现结")]
            ),
            _c("div", { staticClass: "rightFoot", on: { click: _vm.toSell } }, [
              _vm._v("去交料")
            ])
          ])
        ],
        2
      ),
      _c(
        "van-dialog",
        {
          attrs: { "show-confirm-button": false, "get-container": "#app" },
          model: {
            value: _vm.statementShow,
            callback: function($$v) {
              _vm.statementShow = $$v
            },
            expression: "statementShow"
          }
        },
        [
          _c("div", { staticClass: "dialog_title" }, [_vm._v("现价结算")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _c("div", { staticClass: "flex_between padding6" }, [
              _c(
                "div",
                {
                  staticClass: "flex_between col999",
                  staticStyle: { width: "48%" }
                },
                [
                  _c("div", [_vm._v("快递费")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.userInfo.expressFee))])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex_between col999",
                  staticStyle: { width: "48%" }
                },
                [
                  _c("div", [_vm._v("保险费")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.userInfo.insuranceFee))])
                ]
              )
            ]),
            _c("div", { staticClass: "flex_between padding6" }, [
              _c(
                "div",
                {
                  staticClass: "flex_between col999",
                  staticStyle: { width: "48%" }
                },
                [
                  _c("div", [_vm._v("首饰折价")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.userInfo.purificationFee))])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex_between break",
                  staticStyle: { width: "48%" }
                },
                [
                  _c("div", [_vm._v("交料总克重")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.totalWeight))])
                ]
              )
            ]),
            _c("div", { staticClass: "flex_between padding6" }, [
              _c(
                "div",
                {
                  staticClass: "flex_between break",
                  staticStyle: { width: "48%" }
                },
                [
                  _c("div", [_vm._v("剩余黄金")]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(_vm._s(_vm.userInfo.goldIncomingFreeWeight))
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex_between break",
                  staticStyle: { width: "48%" }
                },
                [
                  _c("div", [_vm._v("剩余白银")]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(_vm._s(_vm.userInfo.baiyinIncomingFreeWeight))
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "flex_between padding6" }, [
              _c(
                "div",
                {
                  staticClass: "flex_between break",
                  staticStyle: { width: "48%" }
                },
                [
                  _c("div", [_vm._v("剩余铂金")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.userInfo.ptIncomingFreeWeight))])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex_between break",
                  staticStyle: { width: "48%" }
                },
                [
                  _c("div", [_vm._v("剩余钯金")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.userInfo.pdIncomingFreeWeight))])
                ]
              )
            ])
          ]),
          _vm.totalWeight
            ? _c(
                "div",
                {
                  staticClass: "dialog_title",
                  staticStyle: { "text-align": "left", "margin-bottom": "0" }
                },
                [_vm._v("明细")]
              )
            : _vm._e(),
          _vm.totalWeight
            ? _c("div", { staticClass: "referlist flex_between padding6" }, [
                _c("div", { staticClass: "referlistDetail" }, [_vm._v("品类")]),
                _c("div", { staticClass: "referlistDetail" }, [
                  _vm._v("当前价格")
                ]),
                _c("div", { staticClass: "referlistDetail" }, [
                  _vm._v("结算克重")
                ])
              ])
            : _vm._e(),
          _vm._l(_vm.orderList, function(item, index) {
            return _c(
              "div",
              { key: index, staticClass: "referlist flex_between padding6" },
              [
                _c("div", { staticClass: "referlistDetail" }, [
                  _vm._v(_vm._s(item.name))
                ]),
                _c("div", { staticClass: "referlistDetail" }, [
                  _vm._v(
                    _vm._s(
                      item.name === "白银"
                        ? _vm.pricebaiy
                        : item.name === "铂金"
                        ? _vm.priceboj
                        : item.name === "钯金"
                        ? _vm.pricebaj
                        : _vm.pricehj
                    )
                  )
                ]),
                _c("div", { staticClass: "referlistDetail" }, [
                  _vm._v(_vm._s(item.weight))
                ])
              ]
            )
          }),
          _c("div", { staticClass: "tips" }, [
            _vm._v(" 注：需铂钯银结算请联系企业微信客服 ")
          ]),
          _c("div", { staticClass: "flex_between popbtn padding6" }, [
            _c(
              "div",
              { staticClass: "gocer closebtn", on: { click: _vm.close } },
              [_vm._v("取消")]
            ),
            _c(
              "div",
              { staticClass: "gocer confirmbtn", on: { click: _vm.commit } },
              [_vm._v("确定")]
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex top" }, [
      _c("div", { staticClass: "category" }),
      _c("div", { staticClass: "title" }, [_vm._v("折足总重")]),
      _c("div", { staticClass: "title" }, [_vm._v("剩余折足总重")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }